import React, { useEffect, useState } from 'react'
import Form from '../components/Form';
import VideoDetail from '../components/VideoDetail';
import Button from '../components/Button';
import { convertDurationToMinutes, convertMinutesToHoursMinutes, convertTimetoSeconds, getVideoId } from '../utils/index';
import CustomToastify from "../components/CustomToastify";
import { env } from '../constants/env';
import * as PortOne from "@portone/browser-sdk/v2";
import Progress from '../components/Progress';
import { checkCharacterCount } from '../services/checkCharacterCount';
import axios from 'axios';
import { v4 as uuidv4 } from 'uuid';


function Home({ showEmailPopup, setShowEmailPopup }) {

  const [error, setError] = useState(false);
  const [videoDetail, setVideoDetail] = useState(null);
  const [timeRange, setTimeRange] = useState({
    startHours: "00",
    startMinutes: "00",
    startSeconds: "00",
    endHours: "00",
    endMinutes: "00",
    endSeconds: "00",
    defaultendhours: "00",
    defaultendMinutes: "00",
    defaultendSeconds: "00",
  });
  const [loader, setLoader] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [dubbingDetail, setDubbingDetail] = useState(null);
  const [loaidngBar, setLoadingBar] = useState(false);


  const getVideoDetail = async () => {
    const selectTargetLanguage = localStorage.getItem("selectTargetLanguage");
    const selectSourceLanguage = localStorage.getItem("selectSourceLanguage");
    const selectedURL = localStorage.getItem("selectedURL");

    setLoader(true);
    if (selectTargetLanguage === null) {
      setError("대상 언어를 선택하십시오.");
    } else if (selectSourceLanguage === null) {
      setError("소스 언어를 선택하십시오.");
    } else {
      setError(false);
      try {
        const videoId = getVideoId(localStorage.getItem("selectedURL"));

        const response = await fetch(
          `https://www.googleapis.com/youtube/v3/videos?part=contentDetails,snippet&id=${videoId}&key=${env.GCP_API_KEY}`,
          {
            method: "GET",
          }
        );

        if (response.ok) {
          const data = await response.json();

          if (data.items.length > 0) {
            const duration = data.items[0].contentDetails.duration;

            if (duration == "P0D") {
              setError("YouTube 동영상이 라이브 URL 입니다.");
            } else {
              const totalMinutes = await convertDurationToMinutes(duration);

              const hoursMinutes = await convertMinutesToHoursMinutes(
                totalMinutes
              );

              setVideoDetail({
                totalMinutes: totalMinutes,
                title: data.items[0]?.snippet?.title,
                channelName: data.items[0].snippet?.channelTitle,
                totalDuration: hoursMinutes,
                price: Math.ceil(totalMinutes) * 3000,
              });

              setTimeRange((prevRange) => ({
                ...prevRange,
                endHours: hoursMinutes.split(":")[0],
                endMinutes: hoursMinutes.split(":")[1],
                endSeconds: hoursMinutes.split(":")[2],
                defaultendhours: hoursMinutes.split(":")[0],
                defaultendMinutes: hoursMinutes.split(":")[1],
                defaultendSeconds: hoursMinutes.split(":")[2],
              }));
            }
          } else {
            setError("YouTube URL이 맞는지 확인바랍니다.");
          }
        } else {
          console.error("Error retrieving video details:", response.statusText);
          setError("YouTube URL이 잘못되었습니다.");
          CustomToastify(
            "Opps",
            "동영상 URL이 올바르지 않습니다.",
            "",
            "bg-red",
            "ltr",
            null,
            false
          );
        }
      } catch (error) {
        console.error("Error:", error);
        // Handle error (e.g., show error message to user)
      }
    }
    setLoader(false);
  }

  const portOne = async () => {
    localStorage.setItem("videoTimeRange", JSON.stringify(timeRange));
    setLoader(true)
    try {
      const isValidForBalance = await checkCharacterCount(timeRange)
      if (!isValidForBalance) {
        setError("오늘 사용가능한 AI 리소스가 모두 소진되었습니다. 내일 다시 시도해주세요.");
        return;
      }

      let config = {
        storeId: env.STORE_ID_PORTONE,
        merchantId: env.STORE_ID_PORTONE,
        paymentId: uuidv4(),
        orderName: "AI더빙 이용료",
        totalAmount: videoDetail.price,
        payMethod: "CARD",
        currency: "KRW",
        pgProvider: "TOSSPAYMENTS",
        channelKey: env.CHANNEL_KEY_PORTONE,
      }
      if (window.innerWidth < 768)
        config.redirectUrl = `${window.location}?redirect_status=succeeded`

      const response = await PortOne.requestPayment(config);

      if (response.code)
        window.location.href = `${window.location}?code=${response.code}&redirect_status=false`
      else
        window.location.href = `${window.location}?redirect_status=succeeded`
    } catch (error) {
      console.log(error);
    }
    setLoader(false);
  }

  const validateTimeRange = async () => {
    let {
      startHours,
      startMinutes,
      startSeconds,
      endHours,
      endMinutes,
      endSeconds,
    } = timeRange;

    startHours = startHours.trim() === "" ? "00" : startHours;
    startMinutes = startMinutes.trim() === "" ? "00" : startMinutes;
    startSeconds = startSeconds.trim() === "" ? "00" : startSeconds;
    endHours = endHours.trim() === "" ? "00" : endHours;
    endMinutes = endMinutes.trim() === "" ? "00" : endMinutes;
    endSeconds = endSeconds.trim() === "" ? "00" : endSeconds;



    // Convert hours, minutes, and seconds to total seconds for comparison
    const startTotalSeconds =
      parseInt(startHours) * 3600 +
      parseInt(startMinutes) * 60 +
      parseInt(startSeconds);
    const endTotalSeconds =
      parseInt(endHours) * 3600 +
      parseInt(endMinutes) * 60 +
      parseInt(endSeconds);



    // Check conditions and update state accordingly
    if (
      startTotalSeconds >= endTotalSeconds ||
      (startHours === endHours &&
        startMinutes === endMinutes &&
        startSeconds === endSeconds)
    ) {
      setError("더빙 시작시점과 종료시점 설정을 다시 확인 바랍니다.");
    } else {
      const timeDifferenceSeconds = endTotalSeconds - startTotalSeconds;
      const timeDifferenceMinutes = Math.floor(timeDifferenceSeconds / 60); // Convert seconds to minutes and round up
      const timeForPrice = Math.ceil(timeDifferenceSeconds / 60); // Convert seconds to minutes and round up
      const timeDifferenceHours = Math.floor(timeDifferenceMinutes / 60);
      const remainingMinutes = timeDifferenceMinutes % 60;
      const remainingSeconds = timeDifferenceSeconds % 60;
      const timeDifferenceFormatted = `${timeDifferenceHours
        .toString()
        .padStart(2, "0")}:${remainingMinutes
          .toString()
          .padStart(2, "0")}:${remainingSeconds.toString().padStart(2, "0")}`;
      if (timeDifferenceMinutes > 30) {
        setError("최대 번역가능시간은 30분입니다.");
      } else {
        console.log(timeDifferenceMinutes);
        setError(false);
        setVideoDetail((prevRange) => ({
          ...prevRange,
          ["totalDuration"]: timeDifferenceFormatted,
          ["price"]: timeForPrice * 3000, // Price based on minutes
        }));
      }
    }
  };


  const dubingVideo = async () => {
    setLoader(true);
    setLoadingBar(true)
    const timeInSeconds = await convertTimetoSeconds();
    const target_lang = localStorage.getItem("selectTargetLanguage");
    const source_lang = localStorage.getItem("selectSourceLanguage");
    const source_url = localStorage.getItem("selectedURL");

    const formData = new FormData();
    formData.append("mode", "automatic");
    formData.append("source_lang", source_lang);
    formData.append("target_lang", target_lang);
    formData.append("dubbing_studio", "false");
    formData.append("source_url", source_url);
    formData.append("num_speakers", "0");
    formData.append("highest_resolution", "false");
    formData.append("watermark", "false");
    formData.append("start_time", timeInSeconds?.startTimeInSeconds);
    formData.append("end_time", timeInSeconds?.endTimeInSeconds);
    formData.append("dubbing_studio", "false");

    const config = {
      headers: {
        "xi-api-key": env.ELEVENLABS_API_KEY,
      },
    };
    try {
      const response = await axios.post(
        "https://api.elevenlabs.io/v1/dubbing",
        formData,
        config
      );

      if (response.status === 200) {
        const { dubbing_id, expected_duration_sec } = response.data;
        localStorage.removeItem("completedProgress");
        await registerMail(dubbing_id, expected_duration_sec);
        setDubbingDetail({
          dubbing_id: dubbing_id,
          expected_duration_sec: expected_duration_sec,
        });
        localStorage.setItem("dubbing_id", dubbing_id);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const registerMail = async (dubbing_id, expected_duration_sec) => {
    try {
      const response = await axios.post(`/email`, {
        email: localStorage.getItem("usermail"),
        dubbing_id: dubbing_id,
        target_language: localStorage.getItem("selectTargetLanguage"),
        time_in_sec: expected_duration_sec,
      });

      if (response.status === 200) {
        console.log("Mail sent successfully");
        localStorage.removeItem("usermail");
      }
    } catch (error) {
      console.log("error in registerMail", error);
    }
  }


  const downloadFile = () => {
    const dubbing_id = localStorage.getItem("dubbing_id");
    const target_language = localStorage.getItem("selectTargetLanguage");
    const url = `${env.SERVER_URL}/getVideo/${target_language}/${dubbing_id}`;
    window.open(url, "_blank");

    setPaymentStatus(false);
    localStorage.clear();

  }

  useEffect(() => {
    if (videoDetail != null) {
      validateTimeRange();
    }
  }, [timeRange]);


  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get("redirect_status");
    const code = urlParams.get("code");

    if (status === "succeeded" && !code) {
      setLoader(true);
      CustomToastify(
        "결제 성공",
        "AI 더빙 시작합니다.",
        "",
        "bg-green",
        "ltr",
        null,
        true
      );
      // setShowEmailPopup(true);
      setPaymentStatus(true);
      dubingVideo();
    }
    else if (localStorage.getItem("dubbing_id")) {
      setPaymentStatus(true);
    }
    else if (code) {
      CustomToastify(
        "결제 실패",
        '',
        "",
        // "bg-red",
        "ltr",
        null,
        true
      );
      setPaymentStatus(false);
    }
    else {
      setPaymentStatus(false);
    }
  }, []);

  useEffect(() => {
    if (!showEmailPopup && localStorage.getItem("usermail")) {
      portOne()
    }
  }, [showEmailPopup])

  useEffect(() => {
    console.log(loader, "loader");

  }, [loader])
  return (
    <div className="flex  flex-col items-center justify-center gap-4 md:gap-8  py-10 px-3 md:p-8 pb-16">
      <p className="text-sm md:text-base font-bold text-[#3e61fb] py-1 px-2 md:py-2 md:px-4 border-2 border-[#3e61fb] rounded-full">
        똑똑이 AI labs
      </p>
      <h1 className="font-bold text-xl text-center md:text-6xl">Youtube 영상, AI가 자동번역</h1>
      <p className="text-sm text-center md:text-xl font-bold text-gray-400">
        말하는 사람의 목소리 그대로 다른 언어로 변경해 줍니다.
      </p>


      <div className="w-full flex flex-col gap-2 md:gap-8 border-2 border-[#3e61fb] p-2 md:p-8 rounded-xl">
        <Form setError={setError} setVideoDetail={setVideoDetail} readOnly={paymentStatus} />
        {videoDetail && <VideoDetail videoDetail={videoDetail} timeRange={timeRange} setTimeRange={setTimeRange} readOnly={paymentStatus} />}
        {dubbingDetail && loaidngBar && <Progress expecteed_time={dubbingDetail.expected_duration_sec} setLoader={setLoadingBar} />}

        {!paymentStatus && videoDetail === null && <Button loader={loader} error={error} onClick={getVideoDetail} title={"AI번역"} />}

        {!paymentStatus && videoDetail !== null &&
          <>
            <h1 className='text-sm md:text-base font-medium text-center'>결제 후 더빙완료를 기다리지 않고 서비스를 종료하셔도, 입력하신 이메일로 다운로드URL을 보내드립니다. 7일간 다운로드 가능</h1>
            <Button loader={loader} error={error} onClick={() => setShowEmailPopup(true)} title={"이메일입력 후 결제하기"} />
            <h1 className='text-sm md:text-base font-medium text-center'>AI영상이 생성되지 않은 경우 외에, 기타이슈로 인한 환불이 불가함을 양해부탁드립니다.</h1>
          </>
          }
        {paymentStatus && <Button loader={loaidngBar} error={error} onClick={downloadFile} title={"AI더빙 의뢰완료"} />}

        {error && <p className="flex text-center justify-center flex-row text-sm md:text-base font-bold text-red-500"> {error} </p>}

      </div>

    </div>
  )
}

export default Home
