import React from "react";

export default function Footer() {
  return (
    <div className="flex flex-col gap-2 px-2 w-full pb-5 text-sm">
      <div className="h-[1px] bg-gray-600 w-full"></div>
      <div className=" text-gray-500 py-4 px-2 md:px-6 flex flex-wrap justify-between items-center text-sm ">
        <div className="w-full md:w-1/2 md:mb-0 pb-2">
          사업자 : 412-88-03019
        </div>
        <div className="w-full md:w-1/2 md:mb-0 pb-2">회사명 : 주식회사 코드365</div>
        <div className="w-full md:w-1/2 md:mb-0 pb-2">대표자 : 이상화</div>
        <div className="w-full md:w-1/2 md:mb-0 pb-2">
          통신업 : 2024-서울영등포-0663
        </div>
        <div className="w-full md:w-1/2 md:mb-0 pb-2">
          이메일 : manager.code365@gmail.com
        </div>
        <div className="w-full md:w-1/2 md:mb-0 pb-2">
          소재지 : 서울시 영등포구 여의대로 108, 파크원 타워1
        </div>
      </div>
      <div className="text-gray-500 text-sm font-semibold px-6">
        CODE365. All rights reserved.
      </div>
      <div className="flex gap-x-5 px-6">
        <a
          href="https://code365.notion.site/c69a4e3bbbda4ece906d4d1407e366dd?pvs=4"
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-500 text-sm cursor-pointer hover:underline"
        >
          Term
        </a>
        <a
          href="https://code365.notion.site/2ece263385e348f2b2c095f646a799e5?pvs=4"
          target="_blank"
          rel="noopener noreferrer"
          className="text-gray-500 text-sm cursor-pointer hover:underline"
        >
          Privacy
        </a>
      </div>
    </div>
  );
}
