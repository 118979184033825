import React from 'react'

function VideoDetail({ videoDetail, timeRange, setTimeRange }) {

    const handleTimeChange = async (key, value) => {
        // Ensure only numbers are entered and limit to 2 characters
        const sanitizedValue = value.replace(/\D/g, "").slice(0, 2);
        let newValue = sanitizedValue;
    
        // Check if key is related to end time and apply validations
        if (key === "endHours") {
          const newHours = parseInt(sanitizedValue);
          const defaultEndHours = parseInt(timeRange.defaultendhours);
    
          // Check if endHours exceed defaultendhours
          newValue =
            newHours > defaultEndHours ? timeRange.endHours : sanitizedValue;
        } else if (key === "endMinutes") {
          const newMinutes = parseInt(sanitizedValue);
          const defaultEndMinutes = parseInt(timeRange.defaultendMinutes);
    
          // Check if endMinutes exceed defaultendMinutes
          newValue =
            newMinutes > defaultEndMinutes ? timeRange.endMinutes : sanitizedValue;
        } else if (key === "endSeconds") {
          const newSeconds = parseInt(sanitizedValue);
          const defaultEndSeconds = parseInt(timeRange.defaultendSeconds);
    
          // Check if endSeconds exceed defaultendSeconds
          newValue =
            newSeconds > defaultEndSeconds ? timeRange.endSeconds : sanitizedValue;
        }
    
        setTimeRange((prevRange) => ({
          ...prevRange,
          [key]: newValue,
        }));
      };
  return (
    <>
    <div className="text-base text-black font-bold flex w-full ">
      {videoDetail?.title}
    </div>
    <div className="text-base text-gray-500  flex w-full ">
      {videoDetail?.channelName}
    </div>
    <div className="flex justify-center items-center w-full ">
      <div className="flex gap-x-2 ">
        <input
          className="border-[1px] border-black bg-[#EEEEEE] w-10 text-center"
          type="text"
          defaultValue={timeRange.startHours}
          onChange={(e) =>
            handleTimeChange("startHours", e.target.value)
          }
          maxLength={2}
        />{" "}
        :
        <input
          className="border-[1px] border-black bg-[#EEEEEE] w-10 text-center"
          type="text"
          defaultValue={timeRange.startMinutes}
          onChange={(e) =>
            handleTimeChange("startMinutes", e.target.value)
          }
          maxLength={2}
        />
        :
        <input
          className="border-[1px] border-black bg-[#EEEEEE] w-10 text-center"
          type="text"
          defaultValue={timeRange.startSeconds}
          onChange={(e) =>
            handleTimeChange("startSeconds", e.target.value)
          }
          maxLength={2}
        />
      </div>
      {/* Input fields for end time */}
      <p className="text-base text-black px-2">~</p>
      <div className="flex gap-x-2 ">
        <input
          className="border-[1px] border-black bg-[#EEEEEE] w-10 text-center"
          type="text"
          defaultValue={timeRange.endHours}
          onChange={(e) =>
            handleTimeChange("endHours", e.target.value)
          }
          maxLength={2}
        />{" "}
        :
        <input
          className="border-[1px] border-black bg-[#EEEEEE] w-10 text-center"
          type="text"
          defaultValue={timeRange.endMinutes}
          onChange={(e) =>
            handleTimeChange("endMinutes", e.target.value)
          }
          maxLength={2}
        />
        :
        <input
          className="border-[1px] border-black bg-[#EEEEEE] w-10 text-center"
          type="text"
          defaultValue={timeRange.endSeconds}
          onChange={(e) =>
            handleTimeChange("endSeconds", e.target.value)
          }
          maxLength={2}
        />
      </div>
    </div>
    <div className="flex justify-between">
      <p>{`재생길이 | ${videoDetail?.totalDuration}`}</p>
      <p>{`더빙비용 | ${videoDetail?.price} won`}</p>
    </div>
  </>
  )
}

export default VideoDetail
