export const convertTimetoSeconds = async () => {
  const videoTimeRange = JSON.parse(localStorage.getItem("videoTimeRange"));

  const startHoursInSeconds = parseInt(videoTimeRange.startHours, 10) * 3600; // 1 hour = 3600 seconds
  const startMinutesInSeconds = parseInt(videoTimeRange.startMinutes, 10) * 60; // 1 minute = 60 seconds
  const startSeconds = parseInt(videoTimeRange.startSeconds, 10);
  const startTimeInSeconds =
    startHoursInSeconds + startMinutesInSeconds + startSeconds;

  // Convert hours, minutes, and seconds to seconds for end time
  const endHoursInSeconds = parseInt(videoTimeRange.endHours, 10) * 3600; // 1 hour = 3600 seconds
  const endMinutesInSeconds = parseInt(videoTimeRange.endMinutes, 10) * 60; // 1 minute = 60 seconds
  const endSeconds = parseInt(videoTimeRange.endSeconds, 10);
  const endTimeInSeconds = endHoursInSeconds + endMinutesInSeconds + endSeconds;

  return { startTimeInSeconds, endTimeInSeconds };
};

export const convertMinutesToHoursMinutes = async (totalMinutes) => {
  if (typeof totalMinutes !== "number" || totalMinutes < 0) {
    console.error("Invalid total minutes");
    return null;
  }

  const hours = Math.floor(totalMinutes / 60);
  const minutes = Math.floor(totalMinutes % 60);
  const seconds = Math.floor((totalMinutes % 1) * 60); // Convert decimal part to seconds and round

  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");
  const formattedSeconds = seconds.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

export const convertDurationToMinutes = async (durationString) => {
  if (!durationString.startsWith("PT")) {
    console.error("Invalid duration format");
    return null;
  }

  const match = durationString.match(/PT(\d+H)?(\d+M)?(\d+S)?/);
  if (match) {
    let hours = 0;
    let minutes = 0;
    let seconds = 0;

    if (match[1]) {
      hours = parseInt(match[1].replace("H", ""));
    }
    if (match[2]) {
      minutes = parseInt(match[2].replace("M", ""));
    }
    if (match[3]) {
      seconds = parseInt(match[3].replace("S", ""));
    }

    const totalMinutes = hours * 60 + minutes + seconds / 60;

    // const roundedMinutes = Math.round(totalMinutes);
    return totalMinutes;
  } else {
    console.error("Unable to parse duration");
    return null;
  }
};

export const getVideoId = (url) => {
  const patterns = [
    { type: "live", regex: /\/live\/([^/?]+)/ },
    { type: "regular", regex: /(?:\?|&)v=([^&]+)/ },
    { type: "shorts", regex: /\/shorts\/([^\/?]+)/ },
    { type: "shared", regex: /(?:\.be\/|youtu.be\/)([^\/?]+)/ },
  ];

  for (const { type, regex } of patterns) {
    const match = url.match(regex);
    if (match) {
      return match[1];
    }
  }

  return "";
};

export const uploadURL = (url, setVideoUrl) => {
  setVideoUrl(url);
  localStorage.setItem("selectedURL", url);
};
