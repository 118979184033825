import React, { useState, useEffect } from "react";

const Progress = ({ expecteed_time, setLoader }) => {

    console.log(expecteed_time, "expecteed_time");
    const [completed, setCompleted] = useState(0);

    useEffect(() => {
        const savedStartTime = parseInt(localStorage.getItem("startTimeLoading"));
        const currentTime = Date.now();

        let initialCompleted = 0;
        if (savedStartTime) {
            const elapsedSeconds = (currentTime - savedStartTime) / 1000;
            initialCompleted = Math.min((elapsedSeconds / expecteed_time) * 100, 100);
        } else {
            localStorage.setItem("startTimeLoading", currentTime);
        }

        setCompleted(initialCompleted);

        const interval = setInterval(() => {
            setCompleted(prev => {
                const newCompleted = prev + (100 / expecteed_time);
                const progress = newCompleted >= 100 ? 100 : newCompleted;
                return progress;
            });
        }, 1000); // update every second

        return () => clearInterval(interval); // cleanup on component unmount
    }, []);

    useEffect(() => {
        if(completed >= 100) {
            setLoader(false)
            localStorage.removeItem("startTimeLoading");
        }
        localStorage.setItem("completedProgress", completed);
    }, [completed]);

    return (
        <div className="rounded-lg h-5 bg-gray-300 w-full overflow-hidden flex justify-center items-center relative">
            <div className="h-full bg-[#3e61fb] absolute left-0" style={{ width: `${completed}%` }}></div>
            <p className="text-sm text-center text-white font-bold z-10">{completed.toFixed(0)}%</p>
        </div>
    );
};

export default Progress;
